import * as Yup from "yup";
const digitsOnly = (value) => /^\d+$/.test(value);

export const signinSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Please fill a valid email"),
  password: Yup.string()
    .required("password is required")
    .min(6, "password must be atleast 6 characters")
    .max(15, "password must be less than 16 characters"),
});

export const editProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Firstname is required")
    .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
    .min(3, "Firstname must be atleast 3 characters")
    .max(15, "Firstname must be less than 16 characters"),
  lastName: Yup.string()
    .required("Lastname is required")
    .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
    .min(3, "Lastname must be atleast 3 characters")
    .max(15, "Lastname must be less than 16 characters"),
  contactNo: Yup.string()
  .required("Contact Number is required")
  .test("Digits only", "The field should have digits only", digitsOnly),
  salutation: Yup.string().required("Salutation is required"),
});

export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Old Password is required").trim(),
  newPassword: Yup.string().required("New Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("newPassword"), null], "password must match"),
});
export const patientFormSchema = Yup.object().shape({
  gender: Yup.string().required("Gender is required"),
  dob: Yup.string().required("Date of birth is required"),
  registrationDate: Yup.string().required("Visit date is required"),
  salutation: Yup.string().required("Honorific is required"),
  firstName: Yup.string()
    .required("Firstname is required")
    .min(3, "Firstname must be atleast 3 characters")
    .max(15, "Firstname must be less than 16 characters"),
  lastName: Yup.string()
    .required("Lastname is required")
    .min(3, "Lastname must be atleast 3 characters")
    .max(15, "Lastname must be less than 16 characters"),
  email: Yup.string()
    .required("Email is required")
    .email("Please fill a valid email"),
  contactNo: Yup.string()
    .required("Phone Number is required")
    .test("Digits only", "The field should have digits only", digitsOnly),
  address: Yup.string().required("Address is required"),
  zipcode: Yup.string().required("Zip code is required"),
  city: Yup.string().required("City is required"),
});

export const healthCareSchema = Yup.object().shape({
  honorific: Yup.string().required("Salutation is required"),
  firstName: Yup.string()
    .required("Firstname is required")
    .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
    .min(3, "Firstname must be atleast 3 characters")
    .max(15, "Firstname must be less than 16 characters"),
  lastName: Yup.string()
    .required("Lastname is required")
    .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
    .min(3, "Lastname must be atleast 3 characters")
    .max(15, "Lastname must be less than 16 characters"),
  email: Yup.string()
    .required("Email is required")
    .email("Please fill a valid email"),
  title: Yup.string().required("Title is required"),
  specilization: Yup.string().required("specilization is required"),
  address: Yup.string().required("Address is required"),
});

export const consultationFormSchema = Yup.object().shape({
    symptoms: Yup.string().required("Symptoms is required"),
    radiates: Yup.array().min(1).required("Radiates is required"),
    describeSymptoms: Yup.array().min(1).required("Describe symptoms is required"),
    begin: Yup.string().required("Began is required"),
    ago: Yup.string().required("Ago is required"),
    frequency: Yup.string().required("Frequency is required"),
    onSet: Yup.string().required("Onset is required"),
    describeInjury: Yup.string().required("Injury is required"),
    palliative:  Yup.array().min(1).required("Palliative is required"),
    provocative: Yup.array().min(1).required("Provocative is required"),
    warnings: Yup.array().min(1).required("Warinigs is required"),
    additionalNote: Yup.string().required("Additional Note is required"),
    therapist:Yup.array().min(1).required("Therapist is required"),
    help: Yup.string().required("Symptoms is required"),
    linkReports: Yup.string().required("Reports is required"),
    goal: Yup.string().required("Patient Goal is required"),
  })
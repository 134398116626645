import React from "react";
import DatePicker from "react-date-picker";
import { Link } from "react-router-dom";

const ConsultationList = () => {
  return (
    <div className="row">
      <div className="card">
        <div className="card-header border-bottom">
          <h5 className="mb-0">Consultation</h5>
        </div>
        <div className="table-responsive">
          <table className="table table-hover table-nowrap">
            <thead className="table-light">
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Consult Date</th>
                <th scope="col">Doctor Name</th>
                <th scope="col">Action</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1 </td>
                <td>23-01-2022</td>
                <td>
                  <a className="text-heading font-semibold" href="#">
                    Jhon Deo
                  </a>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-square btn-primary"
                  >
                    <i className="bi bi-pencil"></i>
                  </button>
                  <a href="#" className="btn btn-sm btn-primary">
                    View
                  </a>
                </td>
              </tr>
              <tr>
                <td>2 </td>
                <td>23-01-2022</td>
                <td>
                  <a className="text-heading font-semibold" href="#">
                    Jhon Deo
                  </a>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-square btn-primary"
                  >
                    <i className="bi bi-pencil"></i>
                  </button>
                  <a href="#" className="btn btn-sm btn-primary">
                    View
                  </a>
                </td>
              </tr>
              <tr>
                <td>3 </td>
                <td>23-01-2022</td>
                <td>
                  <a className="text-heading font-semibold" href="#">
                    Jhon Deo
                  </a>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-square btn-primary"
                  >
                    <i className="bi bi-pencil"></i>
                  </button>
                  <a href="#" className="btn btn-sm btn-primary">
                    View
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ConsultationList;

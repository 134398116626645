import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { changePasswordSchema } from "../../validation/schema";
import { changePasswordApi } from "../../api/index";
import { useNavigate } from "react-router-dom";
import { InputErrorMessage } from "../../common/errors/ErrorMessage";
import Navbar from "../../Components/Headers/Navbar";
import Sidebar from "../../Components/Headers/Sidebar";

const initialValues = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const ChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: initialValues,
      validationSchema: changePasswordSchema,
      onSubmit: async (values, action) => {
        try {
          action.resetForm();
          const response = await changePasswordApi(values);
          if (response.status === 200) {
            toast.success(response.data.message, { toastId: "success001" });
            navigate("/dashboard");
          } else {
            toast.error("Something went wrong.", { toastId: "err01" });
          }
        } catch (err) {
          console.log(err);
          const message =
            err.response?.data?.message || err.response.statusText;
          toast.error("Old Password is incorrect!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        }
      },
    });
  return (
    <div>
      <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        <Sidebar />

        <div className="flex-lg-1 h-screen rPadd overflow-y-lg-auto container-fluid gap-5">
          <Navbar title="Change Password" />
          <div className="container-fluid bg-surface-secondary">
            {/* <Navbar /> */}
            <div className="row">
              {/* <Sidebar /> */}
              <div className="col-lg-10 col-md-9 col-xl-7 py-20 mx-auto">
                <div className="card">
                  <div className="card-body p-10">
                    <div className="text-center mb-12">
                      <h1>
                        <i className="bi bi-shield-lock"></i>
                      </h1>
                      <h1 className="ls-tight font-bolder mt-6">
                        Change Your Password
                      </h1>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="mb-5">
                        <label className="form-label" htmlFor="old_password">
                          Old Password
                        </label>
                        <input
                          type="password"
                          name="oldPassword"
                          id="old_password"
                          placeholder="Old Password"
                          value={values.oldPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control form-control-solid ${
                            touched.oldPassword && Boolean(errors.oldPassword)
                              ? "border-danger"
                              : ""
                          }`}
                        />
                        <InputErrorMessage
                          error={touched.oldPassword && errors.oldPassword}
                          marginBottom={-15}
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label" htmlFor="new_password">
                          New Password
                        </label>
                        <input
                          type="password"
                          name="newPassword"
                          id="new_password"
                          placeholder="New Password"
                          value={values.newPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control form-control-solid ${
                            touched.newPassword && Boolean(errors.newPassword)
                              ? "border-danger"
                              : ""
                          }`}
                        />
                        <InputErrorMessage
                          error={touched.newPassword && errors.newPassword}
                          marginBottom={-15}
                        />
                      </div>
                      <div className="mb-6">
                        <label
                          className="form-label"
                          htmlFor="re_enter_password"
                        >
                          Confirm New Password
                        </label>
                        <input
                          type="password"
                          name="confirmPassword"
                          id="re_enter_password"
                          placeholder="Re-Enter Password"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control form-control-solid ${
                            touched.confirmPassword &&
                            Boolean(errors.confirmPassword)
                              ? "border-danger"
                              : ""
                          }`}
                        />
                        <InputErrorMessage
                          error={
                            touched.confirmPassword && errors.confirmPassword
                          }
                          marginBottom={-15}
                        />
                      </div>

                      <div className="mt-10">
                        <button
                          type="submit"
                          className="btn btn-primary w-full"
                        >
                          Update Password
                        </button>
                      </div>
                      {/* <ToastContainer autoClose={2000}/> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;

import React from "react";
import { Routes, Route } from "react-router-dom";
import Signin from "../Pages/Account/Signin";
import ChangePassword from "../Pages/Account/ChangePassword";
import EditProfile from "../Pages/Account/EditProfile";
import Dashboard from "../Pages/Dashboard/Dashboard";
import PrivateRoute from "../private/PrivateRoute";
import Patients from "../Pages/Patient/Patients";
import HealthCareActivity from "../Pages/Dashboard/HealthCareActivity";
import RecentActivity from "../Pages/Dashboard/RecentActivity";
import PatientDetail from "../Pages/Patient/PatientDetail/PatientDetails";
import Consultation from "../Pages/Patient/PatientDetail/Consultation";
const MainRouter = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/recover"
          element={
            <PrivateRoute>
              <ChangePassword />
            </PrivateRoute>
          }
        />
        <Route
          path="/editprofile"
          element={
            <PrivateRoute>
              <EditProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/patients"
          element={
            <PrivateRoute>
              <Patients />
            </PrivateRoute>
          }
        />
        <Route
          path="/healtcareactivity"
          element={
            <PrivateRoute>
              <HealthCareActivity />
            </PrivateRoute>
          }
        />
        <Route
          path="/recentactivity"
          element={
            <PrivateRoute>
              <RecentActivity />
            </PrivateRoute>
          }
        />
        <Route
          path="/patients/profile/:id"
          element={
            <PrivateRoute>
              <PatientDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/consultation/:id"
          element={
            <PrivateRoute>
              <Consultation />
            </PrivateRoute>
          }
        />
        <Route path="/" element={<Signin />} />
      </Routes>
    </React.Fragment>
  );
};

export default MainRouter;

import React from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <>
      <nav
        className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg"
        id="sidebar"
      >
        <div className="container-fluid">
          {/* <!-- Toggler --> */}
          <button
            className="navbar-toggler ms-n2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapse"
            aria-controls="sidebarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* <!-- Brand --> */}
          <Link
            className="navbar-brand py-lg-2 mb-lg-5 px-lg-6 me-0 ms-4"
            to="/dashboard"
          >
            <img src="/kit/img/logos/logo.png" alt="..." />
          </Link>
          {/* <!-- User menu (mobile) --> */}
          <div className="navbar-user d-lg-none">
            {/* <!-- Dropdown --> */}
            <div className="dropdown">
              {/* <!-- Toggle --> */}
              <Link
                to="#"
                id="sidebarAvatar"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="avatar-parent-child">
                  <img
                    alt="..."
                    src="/kit/img/people/img-profile.jpg"
                    className="avatar avatar- rounded-circle"
                  />
                  <span className="avatar-child avatar-badge bg-success"></span>
                </div>
              </Link>
            </div>
          </div>
          {/* <!-- Collapse --> */}
          <div className="collapse navbar-collapse" id="sidebarCollapse">
            {/* <!-- Navigation --> */}

            <ul className="navbar-nav ps-4">
              <li className="nav-item">
                <Link className="nav-link" to="/dashboard">
                  <i className="bi bi-briefcase"></i>
                  Dashboard
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="#">
                  <i className="bi bi-activity"></i>
                  Healthcare Providers
                </Link>
              </li> */}

              <li className="nav-item">
                <Link className="nav-link" to="/patients">
                  <i className="bi bi-people"></i>
                  Patients
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#">
                  <i className="bi bi-calendar2"></i>
                  Consultation
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#">
                  <i className="bi bi-clipboard"></i>
                  Examination
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="#">
                  <i className="bi bi-calendar3"></i>
                  My Schedule
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#">
                  <i className="bi bi-clipboard-data"></i>
                  Progress and Reports
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#">
                  <i className="bi bi-receipt-cutoff"></i>
                  Patients Billing
                </Link>
              </li>
            </ul>

            {/* <!-- Push content down --> */}
            <div className="mt-auto"></div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;

import { postApiWithoutToken, getUser_api, updateUser_api, updateImageProfileApi,patchPasswordApi, postApi } from "./api_request";

/* Login APIs */
export const loginApi = (payload) => {
    return postApiWithoutToken("/user/auth/loginProvider", payload)
}
/* Update User Profile APIs */
export const updateUserProfileApi = (payload) => {
    return updateUser_api("/user/update", payload)
}

/* Get User Profile APIs */
export const UserProfileApi = () => {
    return getUser_api("/user/byAuth")
} 


export const imageProfileApi = (payload) => {
  return updateImageProfileApi("/common/uploads/file", payload)
} 






/* Change Password APIs */
export const changePasswordApi = (payload) => {
  return patchPasswordApi("/user/changePassword", payload)
}


/*  create patients API  */
export const createPatientApi = (payload)=>{
  return postApi("/user/patient/create",payload)
}

export const getPatientDetailsApi=()=>{
  return getUser_api("/user/patient/all")
}

export const getDetailsById=(id)=>{
  return getUser_api(`/user/patient//byId?id=${id}`)
}

export const updatePatientDetails = (payload,data)=>{
  return updateUser_api("/user/patient/update",payload,data);
}

/*create consultation API*/ 
 
export const createConsultationApi =(payload)=>{
  return postApi(`/user/consultation/create`,payload)
}
import React, { useContext, useEffect, useState, useRef } from "react";
import { Formik, useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputErrorMessage } from "../../common/errors/ErrorMessage";
import AppContext from "../../appContext";
import { editProfileSchema } from "../../validation/schema";
import { updateUserProfileApi } from "../../api";
import { SERVER_ENDPOINT } from "../../api/baseurl";
import { imageProfileApi } from "../../api";
import Navbar from "../../Components/Headers/Navbar";
import Sidebar from "../../Components/Headers/Sidebar";

const EditProfile = () => {
  const [profile, setProfile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const appContext = useContext(AppContext);
  const { getUserAPI, setLoggedInUser } = appContext;
  const { loggedInUser } = appContext.state;
  const ref = useRef();
  const { firstName, lastName, contactNo, email, profilePic, salutation } =
    loggedInUser;
  const {
    values,
    errors,
    touched,
    dirty,
    handleSubmit,
    handleBlur,
    handleChange,
    setErrors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: firstName ? firstName : "",
      lastName: lastName ? lastName : "",
      contactNo: contactNo ? contactNo : "",
      profilePic: profilePic ? profilePic : "",
      salutation: salutation ? salutation : "",
      email: email ? email : "",
    },
    validationSchema: editProfileSchema,
    onSubmit: async (values) => {
      try {
        const response = await updateUserProfileApi(values);
        if (response.status === 200) {
          setLoggedInUser(response.data.user);
          toast.success("Profile Updated Successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Something went wrong.", { toastId: "err01" });
        }
      } catch (err) {
        const message = err.response?.data?.message || err.response.statusText;
        toast.error(message, { toastId: "err01" });
        setIsLoading(false);
      }
    },
  });

  const handleFileChange = async (e) => {
    try {
      let file = e.target.files[0];
      if (!file) {
        return;
      }
      const type = file.type.split("/")[1];
      const imageType = ["jpeg", "jpg", "png"];
      const validImageType = imageType.includes(type);
      if (!validImageType) {
        return toast.error("Upload a valid image : jpeg, png, jpg", {
          toastId: "error01",
        });
      } else if (file.size > 10000001) {
        return toast.error("Image size cannot be more than 10 MB", {
          toastId: "error01",
        });
      } else {
        let fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = function () {
          setProfile(fr.result);
        };
        setErrors({});
      }

      const formdata = new FormData();
      formdata.append("file", e.target.files[0]);
      const response = await imageProfileApi(formdata);
      if (response.status === 200) {
        setFieldValue("profilePic", response.data.profilePic);
        toast.success(response.data.message, { toastId: "success001" });
      }
    } catch (err) {
      // console.log(err);
      toast.error(err.message);
    }
  };
  // useEffect(() => {
  //   if (loggedInUser) {
  //     setFieldValue("salutation", salutation);
  //     setFieldValue("firstName", firstName);
  //     setFieldValue("lastName", lastName);
  //     setFieldValue("contactNo", contactNo);
  //     setFieldValue("email", email);
  //     setFieldValue("profilePic", profilePic);
  //   } else {
  //     setFieldValue({
  //       salutation: "",
  //       firstName: "",
  //       lastName: "",
  //       contactNo: "",
  //       email: "",
  //       profilePic: "",
  //     });
  //   }
  // }, [loggedInUser]);
  useEffect(() => {
    if (!dirty) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [dirty]);
  useEffect(() => {
    getUserAPI();
  }, []);
  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
      <Sidebar />
      <div className="flex-lg-1 h-screen rPadd overflow-y-lg-auto container-fluid gap-5">
        <Navbar title="Edit Profile" />
        <div className="col-md-8 col-sm-12 mx-auto py-10">
          <div className="row">
            <div className="card rPadd">
              <div className="card-body">
                <div className="row">
                  <h2 className="h2 mb-5 mt-5">Edit Profile</h2>
                  <hr className="mb-10" />
                  <div className="col-md-4 col-sm-4">
                    <h3 className="h4 mb-5 text-center img-head">
                      Upload Image
                    </h3>
                    <div className="d-flex flex-column align-items-center text-center">
                      <a className="avatar w-48 h-48 border border-body border-4 rounded shadow position-relative">
                        <div
                          className="border position-absolute rounded text-center bg-white bottom-0 end-0"
                          style={{
                            height: "2em",
                            width: "2em",
                            cursor: "pointer",
                          }}
                        >
                          <label htmlFor="image">
                            <input
                              name="profilePic"
                              id="image"
                              type="file"
                              style={{ display: "none" }}
                              accept="image/png, image/jpeg, image/jpg"
                              hidden={true}
                              ref={ref}
                              onChange={handleFileChange}
                            />
                            <i className="bi bi-camera text-black"></i>
                          </label>
                        </div>
                        <img
                          alt="..."
                          src={
                            profile
                              ? profile
                              : values?.profilePic
                              ? `${SERVER_ENDPOINT}${values?.profilePic}`
                              : `/kit/img/people/img-profile.jpg`
                          }
                          className="rounded img-size"
                        />
                      </a>
                    </div>
                  </div>

                  <div className="col-md-8 col-sm-8">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="mb-5 col-sm-2">
                          <label className="form-label" htmlFor="name">
                            Honorific
                          </label>
                          <select
                            type="string"
                            name="salutation"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.salutation}
                            className={`form-control form-control-solid ${
                              touched?.salutation && Boolean(errors?.salutation)
                                ? "border-danger"
                                : ""
                            }`}
                          >
                            <option value="Dr.">Dr.</option>
                            <option value="Mr.">Mr.</option>
                            <option value="Ms.">Ms.</option>
                          </select>
                          <InputErrorMessage
                            error={touched?.salutation && errors?.salutation}
                            marginBottom={-15}
                          />
                        </div>
                        <div className="mb-5 col-sm-5">
                          <label className="form-label" htmlFor="name">
                            First Name
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            id="name"
                            placeholder="Enter Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.firstName}
                            className={`form-control form-control-solid ${
                              touched?.firstName && Boolean(errors?.firstName)
                                ? "border-danger"
                                : ""
                            }`}
                          />
                          {/* {console.log(Boolean(errors.email))} */}
                          <InputErrorMessage
                            error={touched?.firstName && errors?.firstName}
                            marginBottom={-15}
                          />
                        </div>
                        <div className="mb-5 col-sm-5">
                          <label className="form-label" htmlFor="name">
                            Last Name
                          </label>
                          <input
                            type="text"
                            name="lastName"
                            id="name"
                            placeholder="Enter Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.lastName}
                            className={`form-control form-control-solid ${
                              touched?.lastName && Boolean(errors?.lastName)
                                ? "border-danger"
                                : ""
                            }`}
                          />
                          {/* {console.log(Boolean(errors.email))} */}
                          <InputErrorMessage
                            error={touched?.lastName && errors?.lastName}
                            marginBottom={-15}
                          />
                        </div>
                        <div className="mb-5 col-sm-12">
                          <label className="form-label" htmlFor="name">
                            Contact No
                          </label>
                          <input
                            type="text"
                            name="contactNo"
                            id="name"
                            placeholder="Enter Contact Number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.contactNo}
                            className={`form-control form-control-solid ${
                              touched?.contactNo && Boolean(errors?.contactNo)
                                ? "border-danger"
                                : ""
                            }`}
                          />
                          <InputErrorMessage
                            error={touched?.contactNo && errors?.contactNo}
                            marginBottom={-15}
                          />
                        </div>
                      </div>
                      <div className="mb-5">
                        <label className="form-label" htmlFor="email">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          value={values?.email}
                          id="email"
                          disabled
                        />
                      </div>

                      <div className=" text-center mt-10 mb-10">
                        <button
                          type="submit"
                          className="btn btn-primary w-full"
                          disabled={isEdit}
                        >
                          Update
                        </button>
                      </div>
                      {/* <ToastContainer autoClose={2000}/> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;

import React, { useEffect } from "react";
import { isAuth } from "./auth";
import { Navigate } from "react-router-dom";
import { UserProfileApi } from "../api";

const PrivateRoute = ({ children }) => {
  // const checkToken=()=>{
  //   UserProfileApi().then(
  //     ()=>{
  //       return children;
  //     }
  //   ).catch((error)=>{
  //       console.log(error)
  //       localStorage.removeItem();
  //       window.location.href ="/";
  //   })
  // }

  // useEffect(()=>{
  //   checkToken(); 
  // },[]) 
  const auth = isAuth();
  return auth ? children : <Navigate to="/" />;
};

export default PrivateRoute;

import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../Components/Headers/Sidebar";
import Navbar from "../../../Components/Headers/Navbar";
import HealthcareForm from "./healthcare-form/HealthcareForm";

const HealthcareActivity = () => {
  return (
    <Fragment>
      <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        <Sidebar />
        <div className="flex-lg-1 h-screen rPadd overflow-y-lg-auto container-fluid gap-5">
          <Navbar title="Healthcare Activity" />
          <div className="container-fluid vstack gap-3 mt-5">
            <div className="card">
              <div className="col-xl-12">
                <div className="card">
                  <div className="d-flex card-header border-bottom">
                    <h5 className="mb-0">All Patient List</h5>
                    <div className="ms-auto text-end">
                      {/* <a href="#" className="text-sm font-semibold">See all</a> */}
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-hover table-nowrap">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Created Date</th>
                          <th scope="col">Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              alt="..."
                              src="img-10.jpg"
                              className="avatar avatar-sm rounded-circle me-2"
                            />
                            <a className="text-heading font-semibold" href="#">
                              Kristin Watson
                            </a>
                          </td>
                          <td>23-01-2022</td>
                          <td>
                            <span className="badge badge-lg badge-dot">
                              <i className="bg-success"></i>Active
                            </span>
                          </td>
                          <td className="text-end">
                            <a
                              href="#modalholder"
                              className="btn btn-sm btn-primary border-base"
                              data-bs-toggle="modal"
                              title="Edit"
                            >
                              <span>
                                <i className="fa-solid fa-pen-to-square "></i>
                              </span>
                            </a>
                            <a
                              href="#modalholder"
                              className="btn btn-sm btn-primary border-base"
                              data-bs-toggle="modal"
                              title="View"
                            >
                              <span>
                                <i className="fa-solid fa-eye"></i>
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img
                              alt="..."
                              src="img-10.jpg"
                              className="avatar avatar-sm rounded-circle me-2"
                            />
                            <a className="text-heading font-semibold" href="#">
                              Robert Fox
                            </a>
                          </td>
                          <td>23-01-2022</td>
                          <td>
                            <span className="badge badge-lg badge-dot">
                              <i className="bg-danger"></i>Deactive
                            </span>
                          </td>
                          <td className="text-end">
                            <a
                              href="#modalholder"
                              className="btn btn-sm btn-primary border-base"
                              data-bs-toggle="modal"
                              title="Edit"
                            >
                              <span>
                                <i className="fa-solid fa-pen-to-square "></i>
                              </span>
                            </a>
                            <a
                              href="#modalholder"
                              className="btn btn-sm btn-primary border-base"
                              data-bs-toggle="modal"
                              title="View"
                            >
                              <span>
                                <i className="fa-solid fa-eye"></i>
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img
                              alt="..."
                              src="img-10.jpg"
                              className="avatar avatar-sm rounded-circle me-2"
                            />
                            <a className="text-heading font-semibold" href="#">
                              Cody Fisher
                            </a>
                          </td>
                          <td>23-01-2022</td>
                          <td>
                            <span className="badge badge-lg badge-dot">
                              <i className="bg-danger"></i>Deactive
                            </span>
                          </td>
                          <td className="text-end">
                            <a
                              href="#modalholder"
                              className="btn btn-sm btn-primary border-base"
                              data-bs-toggle="modal"
                              title="Edit"
                            >
                              <span>
                                <i className="fa-solid fa-pen-to-square "></i>
                              </span>
                            </a>
                            <a
                              href="#modalholder"
                              className="btn btn-sm btn-primary border-base"
                              data-bs-toggle="modal"
                              title="View"
                            >
                              <span>
                                <i className="fa-solid fa-eye"></i>
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img
                              alt="..."
                              src="img-10.jpg"
                              className="avatar avatar-sm rounded-circle me-2"
                            />
                            <a className="text-heading font-semibold" href="#">
                              Theresa Webb
                            </a>
                          </td>
                          <td>23-01-2022</td>
                          <td>
                            <span className="badge badge-lg badge-dot">
                              <i className="bg-success"></i>Active
                            </span>
                          </td>
                          <td className="text-end">
                            <a
                              href="#modalholder"
                              className="btn btn-sm btn-primary border-base"
                              data-bs-toggle="modal"
                              title="Edit"
                            >
                              <span>
                                <i className="fa-solid fa-pen-to-square "></i>
                              </span>
                            </a>
                            <a
                              href="#modalholder"
                              className="btn btn-sm btn-primary border-base"
                              data-bs-toggle="modal"
                              title="View"
                            >
                              <span>
                                <i className="fa-solid fa-eye"></i>
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img
                              alt="..."
                              src="img-10.jpg"
                              className="avatar avatar-sm rounded-circle me-2"
                            />
                            <a className="text-heading font-semibold" href="#">
                              Theresa Webb
                            </a>
                          </td>
                          <td>23-01-2022</td>
                          <td>
                            <span className="badge badge-lg badge-dot">
                              <i className="bg-success"></i>Active
                            </span>
                          </td>
                          <td className="text-end">
                            <a
                              href="#modalholder"
                              className="btn btn-sm btn-primary border-base"
                              data-bs-toggle="modal"
                              title="Edit"
                            >
                              <span>
                                <i className="fa-solid fa-pen-to-square "></i>
                              </span>
                            </a>
                            <a
                              href="#modalholder"
                              className="btn btn-sm btn-primary border-base"
                              data-bs-toggle="modal"
                              title="View"
                            >
                              <span>
                                <i className="fa-solid fa-eye"></i>
                              </span>
                            </a>
                          </td>
                        </tr>

                        {/* <div className="ms-auto">
                      <a href="#" className="text font-semibold">See all</a>
                    </div> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {modal View} */}

          <div
            className="modal fade"
            id="modalholder"
            tabIndex={-1}
            aria-labelledby="modalholder"
            aria-hidden="true"
          >
            <HealthcareForm />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HealthcareActivity;

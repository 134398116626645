import React, { useEffect, useState } from "react";
import MainRouter from "./MainRouter/MainRouter";
import AppContext from "./appContext";
import { isAuth } from "./private/auth";
import { UserProfileApi } from "./api/index";
import { toast, ToastContainer } from "react-toastify";
import { Navigate } from "react-router-dom";

const App = () => {
  const [loggedIn, setLoggedIn] = useState(isAuth);
  const [loggedInUser, setLoggedInUser] = useState({});

  const getUserAPI = async () => {
    try {
      const res = await UserProfileApi();
      setLoggedInUser(res?.data?.user);
    } catch (error) {
      Navigate('/')
      toast.error(error.message)
    }
  };

  useEffect(() => {
    getUserAPI();
  }, [loggedIn]);

  return (
    <AppContext.Provider
      value={{
        state: {
          loggedIn,
          loggedInUser,
        },
        setLoggedIn,
        getUserAPI,
        setLoggedInUser,
      }}
    >
      <MainRouter />
      <ToastContainer autoClose={2000} />
    </AppContext.Provider>
  );
};

export default App;

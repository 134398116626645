import React ,{useState} from "react";
import DatePicker from "react-date-picker";
import { Link, useParams } from "react-router-dom";
import { setLocale } from "yup";
import Sidebar from "../../../Components/Headers/Sidebar";
import Navbar from "../../../Components/Headers/Navbar";
import { useFormik } from "formik";
const Consultation = () => {
    const [show, setShow] = useState(false);
    const patientId = useParams();
    const {values,setFieldValue,handleChange,handleSubmit,resetForm}= useFormik({
        initialValues:{
            patientId: patientId.id,
            symptoms: "",
            radiates: "",
            describeSymptoms: "",
            begin: "",
            ago: "",
            describeInjury: "",
            palliative: "",
            provocative: "",
            frequency: "",
            warnings: "",
            onSet: "",
            additionalNote: "",
            therapist: "",
            help: "",
            linkReports: "",
            goal: "",
        },
        onSubmit:(value)=>{
            setShow(false)
            setFieldValue('symptoms',"")
            resetForm();
            console.log(value)
        }
    })
    const handleSelectChange =(e,name)=>{
        console.log(e.target.value)
        if(e.target.value == "other"){
            console.log("addddddddd")
            setShow(true)
        }else{

            setFieldValue(name,e.target.value);
        }
    }
  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
      <Sidebar />
      <div className="flex-lg-1 h-screen rPadd overflow-y-lg-auto container-fluid gap-5">
        <Navbar title="Patients" />
        <main className="py-6 bg-surface-secondary">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <h4 className="mb-0">Consultation</h4>
                    <span className="btn btn-sm btn-neutral ms-auto">
                      Patient : #12345
                    </span>
                  </div>
                  <hr className="dropdown-divider" />
                  <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-6">
                        <label className="form-label">Symptoms</label>
                        
                        {
                            show ? <input
                            className="form-control"
                            type="text"
                            placeholder="typing here.."
                            value={values.symptoms}
                            name="symptoms"
                            onChange={handleChange}
                          />:<><select
                          className="form-select form-select-lg mb-3"
                          aria-label=".form-select-lg example"
                          value={values.symptoms}
                          name="symptoms"
                          onChange={(e)=>handleSelectChange(e,'symptoms')}
                        >
                          <option hidden>Select Symptoms </option>
                          <option value="Neck pain">Neck pain</option>
                          <option value="Headaches">Headaches</option>
                          <option value="Migraine">Migraine</option>
                          <option value="other">Other</option>
                        </select></>
                        }
                        
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-around align-items-center">
                        <div>
                          <label className="form-label">Lt</label>
                          <div>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="SI"
                            />
                          </div>
                        </div>
                        <div>
                          <label className="form-label">Rt</label>
                          <div>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="SI"
                            />
                          </div>
                        </div>
                        <div>
                          <label className="form-label">Bit</label>
                          <div>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="SI"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <label className="form-label">Radiates To</label>
                        <select
                          className="form-select form-select-lg mb-3"
                          aria-label=".form-select-lg example"
                          value={values.radiates}
                          name="radiates"
                          onChange={handleChange}
                        >
                          <option hidden>Select Radiates To </option>
                          <option value="Head">Head</option>
                          <option value="Shoulder">Shoulder</option>
                          <option value="Upper arm">Upper arm</option>
                          <option value="Lower arm">Lower arm</option>
                        </select>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <label className="form-label">Describe Symptoms</label>
                        <select
                          className="form-select form-select-lg mb-3"
                          aria-label=".form-select-lg example"
                          value={values.describeSymptoms}
                          name="describeSymptoms"
                          onChange={handleChange}
                        >
                          <option hidden>Select Describe Symptoms </option>
                          <option value="An ache">An ache</option>
                          <option value="Sharp">Sharp</option>
                          <option value="Dull">Dull</option>
                          <option value="Shooting">Shooting</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-6 mb-4">
                        <label className="form-label">Began</label>
                        <select className="form-select"
                        value={values.begin}
                        name="begin"
                        onChange={handleChange}
                        >
                          <option hidden>Select Began</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </select>
                      </div>
                      <div className="col-lg-3 col-md-6 mb-4">
                        <label className="form-label">Ago</label>
                        <select className="form-select"
                         value={values.ago}
                         name="ago"
                         onChange={handleChange}>
                          <option hidden>Select Ago</option>
                          <option value="Days">Days</option>
                          <option value="Weeks">Weeks</option>
                          <option value="Month">Month</option>
                          <option value="Year">Year</option>
                        </select>
                      </div>
                      <div className="col-lg-3 col-md-6 mb-4">
                        <label className="form-label">Frequency</label>
                        <select className="form-select"
                        value={values.frequency}
                        name="frequency"
                        onChange={handleChange}
                        >
                          <option hidden>Select Frequency</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </select>
                      </div>
                      <div className="col-lg-3 col-md-6 mb-4">
                        <label className="form-label">On set</label>
                        <select className="form-select"
                        value={values.onSet}
                        name="onSet"
                        onChange={handleChange}
                        >
                          <option hidden>Select on set</option>
                          <option value="Days">Days</option>
                          <option value="Weeks">Weeks</option>
                          <option value="Month">Month</option>
                          <option value="Year">Year</option>
                        </select>
                      </div>
                      <div className="col-12 col-sm-12 mb-4">
                        <label className="form-label">Describe of injury</label>
                        <textarea
                          className="form-control"
                          placeholder=""
                          rows="6"
                          style={{ resize: "none" }}
                          value={values.describeInjury}
                          name="describeInjury"
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="col-lg-4 col-md-6 mb-4">
                        <label className="form-label">Palliative</label>
                        <select className="form-select"
                         value={values.palliative}
                         name="palliative"
                         onChange={handleChange}
                        >
                          <option hidden>Select Palliative</option>
                          <option value="Walking">Walking</option>
                          <option value="Working">Working</option>
                          <option value="Running">Running</option>
                          <option value="Sports">Sports</option>
                        </select>
                      </div>
                      <div className="col-lg-4 col-md-6 mb-4 dropdown">
                        <label className="form-label">Provocative</label>
                        <select className="form-select"
                        value={values.provocative}
                        name="provocative"
                        onChange={handleChange}
                        >
                          <option hidden>Select Provocative</option>
                          <option value="Walking">Walking</option>
                          <option value="Working">Working</option>
                          <option value="Running">Running</option>
                          <option value="Sports">Sports</option>
                        </select>
                      </div>
                      <div className="col-lg-4 col-md-6 mb-4">
                        <label className="form-label">Warnings </label>

                        <select className="form-select"
                        value={values.warnings}
                        name="warnings"
                        onChange={handleChange}
                        >
                          <option hidden>Select Warnings</option>
                          <option value="No Cervical Manual">No Cervical Manual</option>
                          <option value="Osteoporosis">Osteoporosis</option>
                          <option value="Disc Herniation">Disc Herniation</option>
                          <option value="Scoliosis">Scoliosis</option>
                        </select>
                      </div>
                      <div className="col-12 col-sm-12 mb-4">
                        <label className="form-label">Additional Note</label>
                        <textarea
                          className="form-control"
                          placeholder=""
                          rows="6"
                          style={{ resize: "none" }}
                          value={values.additionalNote}
                        name="additionalNote"
                        onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">Other Therapist </label>
                        <select className="form-select"
                        value={values.therapist}
                        name="therapist"
                        onChange={handleChange}
                        >
                          <option hidden>Select Other Therapist</option>
                          <option value="Faimly Doctor">Family Doctor</option>
                          <option value="Orthopedist">Orthopedist</option>
                          <option value="Osteopath">Osteopath</option>
                          <option value="Chiropractor">Chiropractor</option>
                        </select>
                      </div>
                      <div className="col-lg-4 col-md-6 ">
                        <label className="form-label">Did it help?</label>
                        <select className="form-select"
                        value={values.help}
                        name="help"
                        onChange={handleChange}
                        >
                          <option hidden>Select Feedback</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                          <option value="A little">A little</option>
                          <option value="A lot">A lot</option>
                        </select>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <label className="form-label">Add link for reports</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value={values.linkReports}
                        name="linkReports"
                        onChange={handleChange}
                        />
                      </div>

                      <div className="col-12">
                        <label className="form-label mt-5">
                          Patients treatment goal
                        </label>
                        <textarea
                          className="form-control"
                          placeholder=""
                          rows="6"
                          style={{ resize: "none" }}
                          value={values.goal}
                        name="goal"
                        onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>
                    {/* <div className="row">
                                    <div className="col-6 col-sm-3 mb-4">
                                        <label className="form-label">Began</label>
                                        <span className="d-flex " >
                                        
                                           <select className="form-control me-2">
                                                
                                               <option>DD</option>
                                               <option>1</option>
                                               <option>2</option>
                                               <option>3</option>
                                           </select>
                                          
                                           <select className="form-control me-2">
                                               <option>MM</option>
                                               <option>1</option>
                                               <option>2</option>
                                               <option>3</option>
                                           </select>
                                          
                                           <select className="form-control">
                                               <option>YYYY</option>
                                               <option>1</option>
                                               <option>2</option>
                                               <option>3</option>
                                           </select>
                                          
                                           
                                       </span>
                                    </div>
                                    <div className="col-6 col-sm-3 mb-4 ">
                                        <label className="form-label">Ago</label>
                                        <span className="d-flex " >
                                        
                                        <select className="form-control me-2">
                                             
                                            <option>DD</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                        </select>
                                       
                                        <select className="form-control me-2">
                                            <option>MM</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                        </select>
                                       
                                        <select className="form-control">
                                            <option>YYYY</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                        </select>
                                       
                                        
                                    </span>
                                    </div>
                                    <div className="col-6 col-sm-3 mb-4">
                                        <label className="form-label">Frequency</label>
                                        <select className="form-select">
                                            <option></option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                        </select>
                                    </div>
                                    <div className="col-6 col-sm-3 mb-4">
                                        <label className="form-label">On set</label>
                                        <select className="form-select">
                                            <option></option>
                                            <option>Accident</option>
                                            <option>Weeks</option>
                                            <option>Month</option>
                                            <option>Year</option>
                                        </select>
                                    </div>
                                    <div className="col-12 col-sm-12 mb-4">
                                        <label className="form-label">Describe of injury</label>
                                        <textarea
                                            className="form-control"
                                            placeholder=""
                                            rows="6"
                                        ></textarea>
                                    </div>
                                    <div className="col-12 col-sm-4 mb-4 dropdown">
                                        <label className="form-label">Palliative</label>
                                        <div
                                            className="btn dropdown-toggle form-select "
                                            style={{ border: "2px solid #F4F4F5" }}
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        ></div>
                                        <ul
                                            className="dropdown-menu dropdown-menu-none w-100 py-5 overflow-auto"
                                            style={{ width: "95%", height: "250px" }}
                                        >
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Walking{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Working{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Running{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Sports{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Lifting{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Sitting{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Lying down{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Rest{" "}
                                            </li>

                                            <li>
                                                <i className="ms-5 h4 bi bi-plus-circle-fill"></i>
                                                <input
                                                    className="mx-5 my-3 form-label border w-75"
                                                    style={{ width: "75%" }}
                                                />
                                                <br />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-sm-4 mb-4 dropdown">
                                        <label className="form-label">Provocative</label>
                                        <div
                                            className="btn dropdown-toggle form-select "
                                            style={{ border: "2px solid #F4F4F5" }}
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        ></div>
                                        <ul
                                            className="dropdown-menu dropdown-menu-none w-100 py-5 overflow-auto"
                                            style={{ width: "95%", height: "250px" }}
                                        >
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Walking{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Working{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Running{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Sports{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Lifting{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Sitting{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Lying down{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Rest{" "}
                                            </li>
                                            <li>
                                                <i className="ms-5 h4 bi bi-plus-circle-fill"></i>
                                                <input
                                                    className="mx-5 my-3 form-label border w-75"
                                                    style={{ width: "75%" }}
                                                />
                                                <br />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-sm-4 mb-4 dropdown">
                                        <label className="form-label">Warnings </label>
                                        <div
                                            className="btn dropdown-toggle form-select "
                                            style={{ border: "2px solid #F4F4F5" }}
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        ></div>
                                        <ul
                                            className="dropdown-menu dropdown-menu-none w-100 py-5 overflow-auto"
                                            style={{ width: "95%", height: "250px" }}
                                        >
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                No Cervical Manual!{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Osteoporosis{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Disc Herniation{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Scoliosis{" "}
                                            </li>
                                            <li>
                                                <i className="ms-5 h4 bi bi-plus-circle-fill"></i>
                                                <input
                                                    className="mx-5 my-3 form-label border w-75"
                                                    style={{ width: "75%" }}
                                                />
                                                <br />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-sm-12 mb-4">
                                        <label className="form-label">Additional Note</label>
                                        <textarea
                                            className="form-control"
                                            placeholder=""
                                            rows="6"
                                        ></textarea>
                                    </div>
                                    <div className="col-12 col-sm-6 dropdown">
                                        <label className="form-label">Other Therapist </label>
                                        <div
                                            className="btn dropdown-toggle form-select "
                                            style={{ border: "2px solid #F4F4F5" }}
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        ></div>
                                        <ul
                                            className="dropdown-menu dropdown-menu-none w-100 py-5 overflow-auto"
                                            style={{ width: "95%", height: "250px" }}
                                        >
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Family Doctor
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Orthopedist{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Osteopath
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Chiropractor{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Heiplraktiker{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Physiotherapeut{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Massage Therapeut{" "}
                                            </li>
                                            <li>
                                                <input
                                                    className="mx-5 my-3 border border-bottom "
                                                    type="checkbox"
                                                />
                                                Psychologe{" "}
                                            </li>
                                            <li>
                                                <i className="ms-5 h4 bi bi-plus-circle-fill"></i>
                                                <input
                                                    className="mx-5 my-3 form-label border w-75"
                                                    style={{ width: "75%" }}
                                                />
                                                <br />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <label className="form-label">Did it help?</label>
                                        <select className="form-select">
                                            <option></option>
                                            <option>Yes</option>
                                            <option>No</option>
                                            <option>A little</option>
                                            <option>A lot</option>
                                        </select>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <label className="form-label">Add link for reports</label>
                                        <input type="text" className="form-control" placeholder="" />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <label className="form-label">Patients treatment goal</label>
                                        <input type="text" className="form-control" placeholder="" />
                                    </div>
                                </div> */}
                    <div className="row mt-5">
                      <div className="col-md-4 mt-2">
                        <div className="col-auto ">
                          <a
                            href="#addsymptoms"
                            className=""
                            data-bs-toggle="modal"
                          >
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                            >
                              <span>
                                {" "}
                                <i className="bi bi-plus-lg"></i>
                              </span>
                              <span className="d-sm-inline ms-2">Add Symptoms</span>
                            </button>
                          </a>
                        </div>
                      </div>
                      <div className="col-md-8 mt-2 hstack gap-2 justify-content-end">
                        <div className="col-auto ">
                          <button
                            type="button"
                            className="btn btn-sm btn-secondary"
                          >
                            <span className="d-sm-inline ms-2">Clear</span>
                          </button>
                        </div>
                        <div className="col-auto ">
                          <button type="submit" className="btn btn-sm btn-primary">
                            <span className=" d-sm-inline">
                              <i className="bi bi-save me-2"></i>Save
                            </span>
                          </button>
                        </div>
                        <div className="col-auto ">
                          <a href="/examination" className="btn btn-sm btn-primary">
                            <span className=" d-sm-inline ms-2">
                              Start Examination
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* Modal */}
                    <div
                      className="modal fade"
                      id="addsymptoms"
                      tabIndex={-1}
                      aria-labelledby="addsymptoms"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content shadow-3">
                          <div className="modal-header">
                            <div className="h5 text-start">Add Symptoms</div>

                            <button
                              style={{ fontSize: "10px" }}
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            />
                          </div>
                          <hr />
                          <div
                            className="modal-body"
                            style={{ paddingTop: "10px" }}
                          >
                            {/* Text */}
                            {/* <form> */}
                              <div className="mb-4">
                                <div className="row">
                                  <div className="col-12 col-sm-12 mb-2">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="symptoms"
                                      placeholder="Symptom"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex justify-content-between">
                                <a
                                  href="#"
                                  className="btn btn-sm btn-secondary"
                                >
                                  {" "}
                                  cancel{" "}
                                </a>
                                <a href="#" className="btn btn-sm btn-primary">
                                  {" "}
                                  <i className="bi bi-save"></i> Add{" "}
                                </a>
                              </div>
                            {/* </form> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Consultation;

import axios from 'axios'
import { API_BASE_URL } from './baseurl'


export const postApiWithoutToken = (path,data) => {
    return axios.post(API_BASE_URL+path,data,{
              headers: {
                "Content-Type": "application/json",
              },
            })
} 
export const getUser_api = (path) => {
    const token = localStorage.getItem("token")
    return axios.get(API_BASE_URL+path,{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
} 
export const updateUser_api = (path,data,) => {
    const token = localStorage.getItem("token")
    return axios.patch(API_BASE_URL+path,data,{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
} 


export const updateImageProfileApi = (path, data) => {
  const token = localStorage.getItem("token")
  return axios.post(API_BASE_URL + path,  data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`
    }
  })
}
export const patchPasswordApi = (path, data) => {
  const token = localStorage.getItem("token")
  return axios.patch(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
     Authorization: `Bearer ${token}`
    }
  })
}

export const postApi = (path, data) => {
  const token = localStorage.getItem("token");

  return axios.post(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
// import axios from "axios";
// import { API_BASE_URL } from "../utils/baseurl";

// export const postApiWithoutToken = (path, data) => {
//     return axios.post(API_BASE_URL + path, data, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
// }

// export const patchPasswordApi = (path, data) => {
//   const token = localStorage.getItem("token")
//   return axios.patch(API_BASE_URL + path, data, {
//     headers: {
//       "Content-Type": "application/json",
//      Authorization: `Bearer ${token}`
//     }
//   })
// }

// export const patchAdminProfileApi = (path, data) => {
//   const token = localStorage.getItem("token")
//   return axios.patch(API_BASE_URL + path, data, {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`
//     }
//   })
// }


// export const getAdminProfileApi = (path) => {
//   const token = localStorage.getItem("token")
//   return axios.get(API_BASE_URL + path, {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`
//     }
//   })
// }




 
 
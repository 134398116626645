import React, { useEffect ,useState} from "react";
import ConsultationList from "./ConsultationList";
import ExaminationList from "./ExaminationList";
import Sidebar from "../../../Components/Headers/Sidebar";
import Navbar from "../../../Components/Headers/Navbar";
import {toast} from 'react-toastify';
import { InputErrorMessage } from "../../../common/errors/ErrorMessage";
import { useFormik } from "formik";
import { patientFormSchema } from "../../../validation/schema";
import { getDetailsById ,updatePatientDetails} from "../../../api";
import { useParams } from "react-router-dom";
const PatientDetail = () => {
  const {id}=useParams();
  const [patientData , setPatientData]= useState();
  const [isEdit,setIsEdit]=useState(true);
  const { values, errors, touched, handleChange, handleSubmit, resetForm , setValues } =
    useFormik({
      initialValues: {
        gender: "Male",
        dob: "",
        registrationDate: "",
        salutation: "",
        firstName: "",
        lastName: "",
        email: "",
        contactNo: "",
        address: "",
        zipcode: "",
        city: "",
        id:id,
      },
      validationSchema: patientFormSchema,
      onSubmit: async (values) => {
        try {
          const response = await updatePatientDetails(values);
          if (response.status === 200) {
            toast.success("Profile Updated Successfully!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Something went wrong.", { toastId: "err01" });
          }
        } catch (err) {
          const message = err.response?.data?.message || err.response.statusText;
          toast.error(message, { toastId: "err01" });
        }
      },
    });
const getPatientDetailsById = async (id)=>{
  try{
    const res = await getDetailsById(id);
    if (res.status === 200 || res.status === 201) {
      setPatientData(res?.data?.patient)   
    } else {
      toast.error("Something went wrong.", { toastId: "err01" });
    }
  }
  catch(error){
    const message =
    error.response?.data?.message || error.response.statusText;
    toast.error(message, { toastId: "err01" });
  }
}

const editableForm=()=>{
  if(isEdit){
    setIsEdit(false)
  }else{
    setIsEdit(true)
  }
}
    useEffect(()=>{
      getPatientDetailsById(id)
    },[id])
    useEffect(()=>{
      if(patientData){
        setValues({
            gender: patientData.gender,
            dob:patientData.dob,
            registrationDate: patientData.registrationDate,
            salutation: patientData.salutation,
            firstName: patientData.firstName,
            lastName: patientData.lastName,
            email: patientData.email,
            contactNo: patientData.contactNo,
            address: patientData.address,
            zipcode: patientData.zipcode,
            city: patientData.city,
            id:patientData._id,
          }   
        )
      }
    },[patientData]);
   
  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
      <Sidebar />
      <div className="flex-lg-1 h-screen rPadd overflow-y-lg-auto container-fluid gap-5">
        <Navbar title="Patient Profile" />
        <main className="py-6 bg-surface-secondary">
          <div className="container-fluid">
            <div className="row">
              <div className="container-fluid max-w-screen-xl">
                <header className="card mb-5">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <div className="name">
                      <h2 className="h3">Lindsey Johnson</h2>
                      <p className="text-muted">Patient Id : #1234</p>
                    </div>

                    <div className="">
                      <a
                        href="#"
                        className="btn btn-sm btn-primary d-block d-md-inline-block ms-auto ms-md-0"
                      >
                        Patient History
                      </a>
                    </div>
                  </div>
                </header>

                <ul className="nav nav-tabs overflow-x ms-1 mt-4">
                  <li className="nav-item">
                    <a
                      href="#information"
                      data-bs-toggle="tab"
                      className="nav-link active font-bold"
                    >
                      Basic Information
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#consultation"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Consultation
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#examination"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Examination
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div className="tab-pane active" id="information">
                    <div className="row g-6">
                      <div className="col-xl-12">
                        <div className="vstack gap-6">
                          <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                              <h1 className="h2">Basic Information</h1>
                              <div className="">
                                <a
                                  onClick={editableForm}
                                  className="btn btn-sm btn-primary d-block d-md-inline-block ms-auto ms-md-0"
                                >
                                  {isEdit?"Edit": <p>&#10003;</p>}
                                </a>
                              </div>
                            </div>
                            <div className="card-body">
                              <form onSubmit={handleSubmit}>
                                <div className="mb-3 d-flex justify-content-between">
                                  <div>
                                    <div className="col-auto">
                                      <label
                                        className="form-label"
                                        htmlFor="name"
                                      >
                                        Honorific
                                      </label>
                                      <select
                                       disabled={isEdit}
                                        type="string"
                                        name="salutation"
                                        value={values.salutation}
                                        onChange={handleChange}
                                        className={`form-select form-control-solid ${
                                          touched?.salutation &&
                                          Boolean(errors?.salutation)
                                            ? "border-danger"
                                            : ""
                                        }`}
                                      >
                                        <option value="">...</option>
                                        <option value="Dr.">Dr.</option>
                                        <option value="Mr.">Mr.</option>
                                        <option value="Ms.">Ms.</option>
                                      </select>
                                      <InputErrorMessage
                                        error={
                                          touched?.salutation &&
                                          errors?.salutation
                                        }
                                        marginBottom={-15}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-5">
                                    <label
                                      className="form-label"
                                      htmlFor="fname"
                                    >
                                      Firstname
                                    </label>
                                    <input
                                    disabled={isEdit}
                                      type="text"
                                      id="fname"
                                      name="firstName"
                                      placeholder="Enter First Name"
                                      value={values.firstName}
                                      onChange={handleChange}
                                      className={`form-control ${
                                        touched?.firstName &&
                                        Boolean(errors?.firstName)
                                          ? "border-danger"
                                          : ""
                                      }`}
                                    />
                                    <InputErrorMessage
                                      error={
                                        touched?.firstName && errors?.firstName
                                      }
                                      marginBottom={-15}
                                    />
                                  </div>
                                  <div className="col-sm-5">
                                    <label
                                      className="form-label"
                                      htmlFor="lname"
                                    >
                                      Last Name
                                    </label>
                                    <input
                                     disabled={isEdit}
                                      type="text"
                                      id="lname"
                                      placeholder="Enter Last Name"
                                      name="lastName"
                                      value={values.lastName}
                                      onChange={handleChange}
                                      className={`form-control ${
                                        touched?.lastName &&
                                        Boolean(errors?.lastName)
                                          ? "border-danger"
                                          : ""
                                      }`}
                                    />
                                    <InputErrorMessage
                                      error={
                                        touched?.lastName && errors?.lastName
                                      }
                                      marginBottom={-15}
                                    />
                                  </div>
                                </div>
                                <div className="row mb-4">
                                  <div className="col-12 col-sm-6">
                                    <label className="form-label" htmlFor="DOB">
                                      Date of Birth
                                    </label>
                                    <input
                                     disabled={isEdit}
                                      type="date"
                                      id="DOB"
                                      name="dob"
                                      value={values.dob}
                                      onChange={handleChange}
                                      className={`form-control ${
                                        touched?.dob && Boolean(errors?.dob)
                                          ? "border-danger"
                                          : ""
                                      }`}
                                    />
                                    <InputErrorMessage
                                      error={touched?.dob && errors?.dob}
                                      marginBottom={-15}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    <label
                                      className="form-label"
                                      htmlFor="gender"
                                    >
                                      Gender
                                    </label>
                                    <select
                                     disabled={isEdit}
                                      name="gender"
                                      value={values.gender}
                                      onChange={handleChange}
                                      className={`form-select ${
                                        touched?.gender &&
                                        Boolean(errors?.gender)
                                          ? "border-danger"
                                          : ""
                                      }`}
                                    >
                                      <option value="Male">Male</option>
                                      <option value="Female">Female</option>
                                      <option value="Other">Other</option>
                                    </select>
                                    <InputErrorMessage
                                      error={touched?.gender && errors?.gender}
                                      marginBottom={-15}
                                    />
                                  </div>
                                </div>
                                <div className="mb-4">
                                  <div className="row">
                                    <div className="col-12 col-sm-6">
                                      <label
                                        className="form-label"
                                        htmlFor="mobileNumber"
                                      >
                                        {" "}
                                        Phone No.
                                      </label>
                                      <input
                                       disabled={isEdit}
                                        type="text"
                                        id="Email/mobileNumber"
                                        name="contactNo"
                                        value={values.contactNo}
                                        onChange={handleChange}
                                        className={`form-control ${
                                          touched?.contactNo &&
                                          Boolean(errors?.contactNo)
                                            ? "border-danger"
                                            : ""
                                        }`}
                                      />
                                      <InputErrorMessage
                                        error={
                                          touched?.contactNo &&
                                          errors?.contactNo
                                        }
                                        marginBottom={-15}
                                      />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                      <label
                                        className="form-label"
                                        htmlFor="email"
                                      >
                                        Email
                                      </label>
                                      <input
                                       disabled={isEdit}
                                        type="text"
                                        id="email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        className={`form-control ${
                                          touched?.email &&
                                          Boolean(errors?.email)
                                            ? "border-danger"
                                            : ""
                                        }`}
                                      />
                                      <InputErrorMessage
                                        error={touched?.email && errors?.email}
                                        marginBottom={-15}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="mb-4">
                                  <label
                                    className="form-label"
                                    htmlFor="fullAddress"
                                  >
                                    Full Address
                                  </label>
                                  <div className="row">
                                    <div className="col-12 col-sm-12 mb-2">
                                      <input
                                       disabled={isEdit}
                                        type="text"
                                        id="fullAddress"
                                        placeholder="Address"
                                        name="address"
                                        value={values.address}
                                        onChange={handleChange}
                                        className={`form-control ${
                                          touched?.address &&
                                          Boolean(errors?.address)
                                            ? "border-danger"
                                            : ""
                                        }`}
                                      />
                                      <InputErrorMessage
                                        error={
                                          touched?.address && errors?.address
                                        }
                                        marginBottom={-15}
                                      />
                                    </div>
                                    <div className="col-6 col-sm-6 mb-2">
                                      <input
                                       disabled={isEdit}
                                        type="text"
                                        id="fullAddress"
                                        placeholder="Zip Code"
                                        name="zipcode"
                                        value={values.zipcode}
                                        onChange={handleChange}
                                        className={`form-control ${
                                          touched?.zipcode &&
                                          Boolean(errors?.zipcode)
                                            ? "border-danger"
                                            : ""
                                        }`}
                                      />
                                      <InputErrorMessage
                                        error={
                                          touched?.zipcode && errors?.zipcode
                                        }
                                        marginBottom={-15}
                                      />
                                    </div>
                                    <div className="col-6 col-sm-6 mb-2">
                                      <input
                                       disabled={isEdit}
                                        type="text"
                                        id="fullAddress"
                                        placeholder="City"
                                        name="city"
                                        value={values.city}
                                        onChange={handleChange}
                                        className={`form-control ${
                                          touched?.city && Boolean(errors?.city)
                                            ? "border-danger"
                                            : ""
                                        }`}
                                      />
                                      <InputErrorMessage
                                        error={touched?.city && errors?.city}
                                        marginBottom={-15}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex justify-content-start">
                                  <button disabled={isEdit} onClick={() => resetForm()}>
                                    {" "}
                                    <a className="btn btn-secondary me-2">
                                      cancel
                                    </a>
                                  </button>
                                  <button type="submit"  disabled={isEdit}>
                                    <a className="btn btn-primary">
                                      <i className="bi bi-save"></i> Update
                                    </a>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="consultation">
                    <ConsultationList />
                  </div>
                  <div className="tab-pane fade" id="examination">
                    <ExaminationList />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PatientDetail;

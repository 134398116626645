import React, { useEffect ,useState} from "react";
import { Link } from "react-router-dom";
import PatientForm from "./patient-form";
import Navbar from "../../Components/Headers/Navbar";
import Sidebar from "../../Components/Headers/Sidebar";
import { getPatientDetailsApi } from "../../api";
import { toast } from "react-toastify";
const Patients = () => {
  const [patientsData, setPatientsData] = useState();
  const modal = document.querySelector("#addpatient");
  const getPatientDetails = async () => {
    try {
      const res = await getPatientDetailsApi();
      if (res.status === 200 || res.status === 201) {
        // toast.success(res.data.message, { toastId: "succ01" });
        setPatientsData(res?.data?.allPatients);
      } else {
        toast.error("Something went wrong.", { toastId: "err01" });
      }
    } catch (error) {
      const message =
        error.response?.data?.message || error.response.statusText;
        toast.error(message, { toastId: "err01" });
    }
  };
  useEffect(() => {
    getPatientDetails();
  }, []);
  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
      <Sidebar />
      <div className="flex-lg-1 h-screen rPadd overflow-y-lg-auto container-fluid gap-5">
        <Navbar title="Patients" />
        <main className="py-6 bg-surface-secondary">
          <header>
            <div className="container-fluid">
              <div className="py-4 ps-4">
                <div className="row d-flex align-items-center justify-content-between">
                  <div className="col-auto btn btn-white border-base">
                    <span className="h4  font-bold">Date: </span>
                    <span className="">
                      <input type="date" />
                    </span>
                  </div>

                  <div className="col-auto ">
                    <a
                      href="#addpatient"
                      className="btn btn-primary border-base w-100 d-flex"
                      data-bs-toggle="modal"
                    >
                      <span>
                        <i className="bi bi-plus-square-dotted pe-sm-2 p-auto" />{" "}
                      </span>
                      <span className="d-none d-sm-block">
                        Create Patients
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </header>
          {/* Modal */}
          <div
            className="modal fade"
            id="addpatient"
            tabIndex={-1}
            aria-labelledby="addpatient"
            aria-hidden="true"
          >
            <PatientForm value={getPatientDetails} modal={modal} />
          </div>
          <div className="container-fluid">
            <div className="row">
              {patientsData?.map((value,index) => {
                return (
                  <div key={index} className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                    <div className="card patient-info py-3 py-md-3 mb-4">
                      <div className="d-flex justify-content-between px-3 px-md-4">
                        <div className="icon icon-shape bg-blue-200 text-lg rounded-circle">
                          <i className="bi bi-person text-white h2"></i>
                          {/* <img
                                alt="..."
                                src="img-10.jpg"
                                // className="avatar avatar-sm rounded-circle me-2"
                                className="rounded-circle"
                              /> */}
                        </div>
                        <div>
                          <h6 className="text-primary">{`${value?.firstName} ${value?.lastName}`}</h6>
                          <p>Patient Id : #1234</p>
                        </div>
                        <div>
                          <h6>10:00 - 10:30 AM</h6>
                          <p className="text-muted"> Friday, June 26 </p>
                        </div>
                      </div>
                      <hr className="light-hr" />
                      <div className="d-flex flex-column justify-content-start w-100 px-3 px-md-4">
                        <div>
                          <i className="fas fa-map-marker-alt pt-3 psr-3"></i>{" "}
                          {`${value?.address} ${value?.city} ${value?.zipcode}`}
                        </div>
                        <div>
                          <i className="fas fa-phone pt-3 psr-3"></i>{value?.contactNo}
                        </div>
                      </div>
                      <hr className="light-hr" />
                      <div className="d-flex justify-content-between px-3 px-md-4">
                        <div>
                          <div className="d-flex">
                            <p className="pe-2">Gender:</p>
                            <div className="font-weight-bold">{value?.gender}</div>
                          </div>
                          <div className="d-flex">
                            <p className="pe-2">Reports:</p>
                            <div className="font-weight-bold">
                              <i className="text-danger bi bi-file-pdf"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="light-hr" />
                      <div className="d-flex justify-content-end px-4">
                        <Link
                          to={`/patients/profile/${value._id}`}
                          className="btn btn-sm btn-primary mat-button-wrapper"
                        >
                          Read More
                        </Link>
                        <Link
                          to={`/consultation/${value._id}`}
                          className="btn btn-sm btn-primary mat-button-wrapper"
                        >
                          Consult
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Patients;

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../Components/Headers/Navbar";
import Sidebar from "../../Components/Headers/Sidebar";
import AppContext from "../../appContext/index";
const Dashboard = () => {
  const appContext = useContext(AppContext);
  const { user } = appContext.state;

  return (
    <>
      <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        <Sidebar />
        <div className="flex-lg-1 h-screen overflow-y-lg-auto">
          <Navbar title="Dashboard" />
          {/* <!-- Main --> */}
          <main className="py-6 bg-surface-secondary">
            {/* <!-- Offcanvas --> */}

            {/* <!-- Modal --> */}

            {/* <!-- Container --> */}
            <div className="container-fluid">
              {/* <!-- Card stats --> */}
              <div className="row g-6 mb-6">
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <span className="h6 font-semibold text-muted text-lg d-block mb-2">
                            Total Patients
                          </span>
                          <span className="h3 font-bold mb-0">750</span>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape bg-info text-white text-lg rounded-circle">
                            <i className="bi bi-people"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <span className="h6 font-semibold text-muted text-lg d-block mb-2">
                            Upcoming Appointments
                          </span>
                          <span className="h3 font-bold mb-0">215</span>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                            <i className="bi bi-people"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- <div className="mb-6">
                    <div className="card">
                    <div className="card-body">
                        <div className="row">
                        <div className="col">
                            <span className="h6 font-semibold text-muted text-lg d-block mb-5">Pending Fee Alert</span>
                            <span className="h3 btn btn-warning btn-sm font-bold mb-2">Pay Now</span>
                        </div>
                        <div className="col-auto d-flex align-items-center">
                            <div className="">
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sapiente, nihil.
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div> --> */}
              <div className="row g-6 mb-6">
                <div className="col-xl-9">
                  <div className="card">
                    <div className="d-flex card-header border-bottom">
                      <h5 className="mb-0">Patients List</h5>
                      <div className="ms-auto text-end">
                        <Link
                          to="/healtcareactivity"
                          className="text-sm font-semibold text-primary"
                        >
                          See all
                        </Link>
                      </div>
                    </div>


                    <div className="table-responsive">
                      <table className="table table-hover table-nowrap">
                        <thead className="table-light">
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Created Date</th>
                            <th scope="col">Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <img
                                alt="..."
                                src="img-10.jpg"
                                className="avatar avatar-sm rounded-circle me-2"
                              />
                              <a className="text-heading font-semibold" href="#">
                                Kristin Watson
                              </a>
                            </td>
                            <td>23-01-2022</td>
                            <td>
                              <span className="badge badge-lg badge-dot">
                                <i className="bg-success"></i>Active
                              </span>
                            </td>
                            <td className="text-end">
                              <a
                                href="#modalholder"
                                className="btn btn-sm btn-primary border-base"
                                data-bs-toggle="modal"
                                title="Edit"
                              >
                                <span>
                                  <i className="fa-solid fa-pen-to-square "></i>
                                </span>
                              </a>
                              <a
                                href="#modalholder"
                                className="btn btn-sm btn-primary border-base"
                                data-bs-toggle="modal"
                                title="View"
                              >
                                <span>
                                  <i className="fa-solid fa-eye"></i>
                                </span>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                alt="..."
                                src="img-10.jpg"
                                className="avatar avatar-sm rounded-circle me-2"
                              />
                              <a className="text-heading font-semibold" href="#">
                                Robert Fox
                              </a>
                            </td>
                            <td>23-01-2022</td>
                            <td>
                              <span className="badge badge-lg badge-dot">
                                <i className="bg-danger"></i>Deactive
                              </span>
                            </td>
                            <td className="text-end">
                              <a
                                href="#modalholder"
                                className="btn btn-sm btn-primary border-base"
                                data-bs-toggle="modal"
                                title="Edit"
                              >
                                <span>
                                  <i className="fa-solid fa-pen-to-square "></i>
                                </span>
                              </a>
                              <a
                                href="#modalholder"
                                className="btn btn-sm btn-primary border-base"
                                data-bs-toggle="modal"
                                title="View"
                              >
                                <span>
                                  <i className="fa-solid fa-eye"></i>
                                </span>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                alt="..."
                                src="img-10.jpg"
                                className="avatar avatar-sm rounded-circle me-2"
                              />
                              <a className="text-heading font-semibold" href="#">
                                Cody Fisher
                              </a>
                            </td>
                            <td>23-01-2022</td>
                            <td>
                              <span className="badge badge-lg badge-dot">
                                <i className="bg-danger"></i>Deactive
                              </span>
                            </td>
                            <td className="text-end">
                              <a
                                href="#modalholder"
                                className="btn btn-sm btn-primary border-base"
                                data-bs-toggle="modal"
                                title="Edit"
                              >
                                <span>
                                  <i className="fa-solid fa-pen-to-square "></i>
                                </span>
                              </a>
                              <a
                                href="#modalholder"
                                className="btn btn-sm btn-primary border-base"
                                data-bs-toggle="modal"
                                title="View"
                              >
                                <span>
                                  <i className="fa-solid fa-eye"></i>
                                </span>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                alt="..."
                                src="img-10.jpg"
                                className="avatar avatar-sm rounded-circle me-2"
                              />
                              <a className="text-heading font-semibold" href="#">
                                Theresa Webb
                              </a>
                            </td>
                            <td>23-01-2022</td>
                            <td>
                              <span className="badge badge-lg badge-dot">
                                <i className="bg-success"></i>Active
                              </span>
                            </td>
                            <td className="text-end">
                              <a
                                href="#modalholder"
                                className="btn btn-sm btn-primary border-base"
                                data-bs-toggle="modal"
                                title="Edit"
                              >
                                <span>
                                  <i className="fa-solid fa-pen-to-square "></i>
                                </span>
                              </a>
                              <a
                                href="#modalholder"
                                className="btn btn-sm btn-primary border-base"
                                data-bs-toggle="modal"
                                title="View"
                              >
                                <span>
                                  <i className="fa-solid fa-eye"></i>
                                </span>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                alt="..."
                                src="img-10.jpg"
                                className="avatar avatar-sm rounded-circle me-2"
                              />
                              <a className="text-heading font-semibold" href="#">
                                Theresa Webb
                              </a>
                            </td>
                            <td>23-01-2022</td>
                            <td>
                              <span className="badge badge-lg badge-dot">
                                <i className="bg-success"></i>Active
                              </span>
                            </td>
                            <td className="text-end">
                              <a
                                href="#modalholder"
                                className="btn btn-sm btn-primary border-base"
                                data-bs-toggle="modal"
                                title="Edit"
                              >
                                <span>
                                  <i className="fa-solid fa-pen-to-square "></i>
                                </span>
                              </a>
                              <a
                                href="#modalholder"
                                className="btn btn-sm  btn-primary border-base"
                                data-bs-toggle="modal"
                                title="View"
                              >
                                <span>
                                  <i className="fa-solid fa-eye"></i>
                                </span>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card">
                    <div className="card-header border-bottom">
                      <div className="d-flex align-items-center ">
                        <h4 className="mb-0">Recent Activity</h4>
                        <div className="ms-auto text-end">
                          <Link
                            to="/recentactivity"
                            className="text-sm text-primary font-semibold"
                          >
                            See all
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-body py-0 ">
                      <div className="list-group list-group-flush">
                        <div className="list-group-item px-0 position-relative hstack flex-wrap">
                          <div className="flex-1">
                            <div className="d-flex align-items-center mb-1">
                              <a href="#" className="d-block h6 stretched-link">
                                Robert Fox
                              </a>
                              <span className="text-muted text-xs ms-2">1 hr ago</span>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="w-3/4 text-sm text-muted line-clamp-1 me-auto">
                                Some text related to the activity
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="list-group-item px-0 position-relative hstack flex-wrap">
                          <div className="flex-1">
                            <div className="d-flex align-items-center mb-1">
                              <a href="#" className="d-block h6 stretched-link">
                                Robert Fox
                              </a>
                              <span className="text-muted text-xs ms-2">1 hr ago</span>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="w-3/4 text-sm text-muted line-clamp-1 me-auto">
                                Some text related to the activity
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="list-group-item px-0 position-relative hstack flex-wrap">
                          <div className="flex-1">
                            <div className="d-flex align-items-center mb-1">
                              <a href="#" className="d-block h6 stretched-link">
                                Robert Fox
                              </a>
                              <span className="text-muted text-xs ms-2">1 hr ago</span>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="w-3/4 text-sm text-muted line-clamp-1 me-auto">
                                Some text related to the activity
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="list-group-item px-0 position-relative hstack flex-wrap">
                          <div className="flex-1">
                            <div className="d-flex align-items-center mb-1">
                              <a href="#" className="d-block h6 stretched-link">
                                Robert Fox
                              </a>
                              <span className="text-muted text-xs ms-2">1 hr ago</span>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="w-3/4 text-sm text-muted line-clamp-1 me-auto">
                                Some text related to the activity
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="list-group-item px-0 position-relative hstack flex-wrap">
                          <div className="flex-1">
                            <div className="d-flex align-items-center mb-1">
                              <a href="#" className="d-block h6 stretched-link">
                                Robert Fox
                              </a>
                              <span className="text-muted text-xs ms-2">1 hr ago</span>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="w-3/4 text-sm text-muted line-clamp-1 me-auto">
                                Some text related to the activity
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

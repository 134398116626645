import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { healthCareSchema } from "../../../../validation/schema";
import { InputErrorMessage } from "../../../../common/errors/ErrorMessage";
const HealthcareForm = () => {
  const [isEdit, setIsEdit] = useState(true);
  const {
    values,
    errors,
    dirty,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: {
      honorific: "Dr.",  
      firstName: "Owner",
      lastName: "User",
      email: "user@gmail.com",
      title: "Testing",
      specilization: "Tester",
      address: "noida , UP",
    },
    validationSchema: healthCareSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  useEffect(() => {
    if (!dirty) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [dirty]);
  return (
    <div className="modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content shadow-3">
        <div className="modal-header">
          <div className="h3 text-center">Healthcare Providers Info</div>
          <button
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit}>
            {/* Text */}
            <div className="container-fluid max-w-screen-md vstack  p-5 py-11 ">
              <div className="row align-items-center">
                <div className="col-sm-4">
                  <div className="text-center">
                    <a
                      href="#"
                      className="avatar w-48 h-48 border border-body border-4 rounded shadow position-relative"
                    >
                      <img
                        alt="..."
                        src="img-10.jpg"
                        className="rounded"
                      />
                    </a>
                  </div>
                </div>

                <div className="col-sm-8 mx-auto font-bolder">
                  <div className="row pb-4 d-flex justify-content-between align-items-center">
                    <div className="col ">
                      <label>Honorific</label>
                    </div>
                    <div className="col">
                      <select
                        name="honorific"
                        value={values.honorific}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        className={`font-bold text-primary${
                          touched?.honorific && Boolean(errors?.honorific)
                            ? "border-danger"
                            : ""
                        }`}
                      >
                        <option value="Dr.">Dr.</option>
                        <option value="Mr.">Mr.</option>
                        <option value="Ms.">Ms.</option>
                      </select>
                      <InputErrorMessage
                        error={touched?.honorific && errors?.honorific}
                        marginBottom={-15}
                      />
                    </div>
                  </div>
                  <div className="row pb-4 d-flex justify-content-between align-items-center">
                    <div className="col ">
                      <label>First Name</label>
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        name="firstName"
                        className={`font-bold text-primary${
                          touched?.firstName && Boolean(errors?.firstName)
                            ? "border-danger"
                            : "" 
                        }`}
                        value={values.firstName}
                        onChange={handleChange}
                      />
                      <InputErrorMessage
                        error={touched?.firstName && errors?.firstName}
                        marginBottom={-15}
                      />
                    </div>
                  </div>
                  <div className="row pb-4 d-flex justify-content-between align-items-center">
                    <div className="col ">
                      <label>Last Name</label>
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        name="lastName"
                        className={`font-bold text-primary${
                          touched?.lastName && Boolean(errors?.lastName)
                            ? "border-danger"
                            : ""
                        }`}
                        value={values.lastName}
                        onChange={handleChange}
                      />
                      <InputErrorMessage
                        error={touched?.lastName && errors?.lastName}
                        marginBottom={-15}
                      />
                    </div>
                  </div>
                  <div className="row pb-4 d-flex justify-content-between align-items-center">
                    <div className="col ">
                      <label>Email</label>
                    </div>
                    <div className="col">
                      <input
                        type="email"
                        name="email"
                        className={`font-bold text-primary${
                          touched?.email && Boolean(errors?.email)
                            ? "border-danger"
                            : ""
                        }`}
                        value={values.email}
                        onChange={handleChange}
                      />
                      <InputErrorMessage
                        error={touched?.email && errors?.email}
                        marginBottom={-15}
                      />
                    </div>
                  </div>
                  <div className="row pb-4 d-flex justify-content-between align-items-center">
                    <div className="col ">
                      <label>Title</label>
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        name="title"
                        className={`font-bold text-primary${
                          touched?.title && Boolean(errors?.title)
                            ? "border-danger"
                            : ""
                        }`}
                        value={values.title}
                        onChange={handleChange}
                      />
                      <InputErrorMessage
                        error={touched?.title && errors?.title}
                        marginBottom={-15}
                      />
                    </div>
                  </div>
                  <div className="row pb-4 d-flex justify-content-between align-items-center">
                    <div className="col ">
                      <label>Specialization</label>
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        name="specilization"
                        className={`font-bold text-primary${
                          touched?.specilization &&
                          Boolean(errors?.specilization)
                            ? "border-danger"
                            : ""
                        }`}
                        value={values.specilization}
                        onChange={handleChange}
                      />
                      <InputErrorMessage
                        error={touched?.specilization && errors?.specilization}
                        marginBottom={-15}
                      />
                    </div>
                  </div>
                  <div className="row pb-4 d-flex justify-content-between ">
                    <div className="col ">
                      <label>Address</label>
                    </div>
                    <div className="col">
                      <textarea
                        rows="3"
                        name="address"
                        className={`text-primary vertical${
                          touched?.address && Boolean(errors?.address)
                            ? "border-danger"
                            : ""
                        }`}
                        value={values.address}
                        onChange={handleChange}
                      ></textarea>
                      <InputErrorMessage
                        error={touched?.address && errors?.address}
                        marginBottom={-15}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end ">
              <button
                type="submit"
                disabled={isEdit}
                className="btn btn-sm btn-primary me-6 "
              >
                Update
              </button>

              <button type="submit" className="btn btn-sm btn-primary me-6 ">
                Send Link
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HealthcareForm;

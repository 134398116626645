import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { signinSchema } from "../../validation/schema";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppContext from "../../appContext";
import { setToken } from "../../private/auth";
import { InputErrorMessage } from "../../common/errors/ErrorMessage";
import { loginApi } from "../../api/index";

const Signin = () => {
  const appContext = useContext(AppContext);
  const { setLoggedIn } = appContext;
  const { loggedIn } = appContext.state;
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
    acceptCheckbox: false,
  };

  const { values, errors, handleSubmit, handleBlur, touched, handleChange } =
    useFormik({
      initialValues: initialValues,
      validationSchema: signinSchema,
      onSubmit: (values) => {
        loginAPI(values);
      },
    });
  const data = {
    email: values.email,
    password: values.password,
    acceptCheckbox: values.acceptCheckbox,
  };
  const loginAPI = async (values) => {
    try {
      const res = await loginApi(data);
      if (res.status === 200) {
        setLoggedIn(true);
        setToken(res.data.token);
        toast.success("Logged in Successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Something went wrong.", { toastId: "err01" });
      }
    } catch (error) {
      // console.log(error);
      toast.error(error.message)
    }
  };

  useEffect(() => {      
    if (loggedIn) {
      navigate("/dashboard");
    }
  }, [loggedIn]);

  return (
    <>
      <div className="px-5 py-5 p-lg-0 h-screen bg-surface-secondary d-flex flex-column justify-content-center">
        <div className="d-flex justify-content-center">
          <div className="col-12 col-md-9 col-lg-6 min-h-lg-screen d-flex flex-column justify-content-center py-lg-16 px-lg-20 position-relative">
            <div className="row">
              <div className="col-lg-10 col-md-9 col-xl-7 mx-auto">
                <div className="text-center mb-12">
                  <h2 className="ls-tight font-bolder mt-6">
                    Welcome Healthcare Provider
                  </h2>
                  
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="mb-5">
                    <label className="form-label" htmlFor="email">
                      Email address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Your email address"
                      autoComplete="off"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <InputErrorMessage
                      error={touched?.email && errors.email}
                      marginBottom={-15}
                    />
                  </div>
                  <div className="mb-5">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                      </div>
                      <div className="mb-2">
                        <Link
                          to="/recover"
                          className="text-sm text-muted text-primary-hover text-underline"
                        >
                          Forgot password?
                        </Link>
                      </div>
                    </div>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder="Password"
                      autoComplete="off"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <InputErrorMessage
                      error={touched?.password && errors.password}
                      marginBottom={-15}
                    />
                  </div>
                  <div className="mb-5">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="check_example"
                        id="check-remind-me"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="check-remind-me"
                      >
                        Keep me logged in
                      </label>
                    </div>
                  </div>
                  <div>
                    <button type="submit" className="btn btn-primary w-full">
                      Sign in
                    </button>
                    {/* <Link to="#" className="btn btn-primary w-full">
                      Sign in
                    </Link> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;

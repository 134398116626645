import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../appContext/index";
import { SERVER_ENDPOINT } from "../../api/baseurl";
const Navbar = (props) => {
  const appContext = useContext(AppContext);
  const { loggedInUser } = appContext.state;
  const [url, setUrl] = useState()
  const logout = (e) => {
    localStorage.clear();

    window.location.href = "/";
  };
  useEffect(() => {
    if (props?.value) {
      setUrl(props.value)
    }
    else {
      setUrl(loggedInUser?.profilePic)
    }
  }, [props, loggedInUser])
  return (
    <>
      <nav
        className="navbar navbar-light position-lg-sticky top-lg-0 d-none d-lg-block overlap-10 flex-none bg-white border-bottom px-0 py-3"
        id="topbar"
      >
        <div className="container-fluid">
          <div className="col">
            <div className="d-flex align-items-center gap-4">
              <h1 className="h3 ls-tight text-primary">{props.title}</h1>
            </div>
          </div>

          <div className="hstack gap-2"></div>

          {/* <!-- Right menu --> */}
          <div className="navbar-user d-none d-sm-block">
            <div className="hstack gap-3 ms-4">
              <div className="dropdown">
                <Link
                  className="d-flex align-items-center"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <div>
                    <div className="avatar avatar-sm bg-warning rounded-circle text-white">
                      <img className="profilePic" alt="..." src={SERVER_ENDPOINT + url} />
                    </div>
                  </div>
                  <div className="d-none d-sm-block ms-3">
                    <span className="h6">{loggedInUser?.firstName}</span>
                  </div>
                  <div className="d-none d-md-block ms-md-2">
                    <i className="bi bi-chevron-down text-muted text-xs"></i>
                  </div>
                </Link>
                <div className="dropdown-menu dropdown-menu-end">
                  <div className="dropdown-header">
                    <span className="d-block text-sm text-muted mb-1 badge badge-lg badge-dot text-start">
                      <i className="bg-success"></i> Signed in as
                    </span>
                    <span className="d-block text-heading font-semibold ms-5">
                      {loggedInUser?.firstName}
                    </span>
                  </div>
                  <div className="dropdown-divider"></div>

                  <Link className="dropdown-item" to="/editprofile">
                    <i className="fa-solid fa-user-pen me-3 text-muted"></i>Edit profile
                  </Link>
                  <Link className="dropdown-item" to="/recover">
                    <i className="bi bi-shield-lock-fill me-3 text-muted"></i>Change Password
                  </Link>
                  {/* <!-- <div className="dropdown-divider"></div> --> */}

                  <Link className="dropdown-item" onClick={logout}>
                    <i className="fa-solid fa-right-from-bracket me-3 text-muted"></i>Logout
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;

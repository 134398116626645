import { useFormik } from "formik";
import React from "react";
import { InputErrorMessage } from "../../../common/errors/ErrorMessage";
import { patientFormSchema } from "../../../validation/schema";
import { createPatientApi } from "../../../api";
import { toast } from "react-toastify";
const PatientForm = (props) => {
  const { values, errors, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        gender: "Male",
        dob: "",
        registrationDate: "",
        salutation: "",
        firstName: "",
        lastName: "",
        email: "",
        contactNo: "",
        address: "",
        zipcode: "",
        city: "",
      },
      validationSchema: patientFormSchema,
      onSubmit: async (val) => {
        console.log(val);
        try {
          const res = await createPatientApi(val);
          if (res.status === 200 || res.status === 201) {
            toast.success(res.data.message, { toastId: "succ01" });
            resetForm();
            props.value();
            props.modal.click();
          } else {
            toast.error("Something went wrong.", { toastId: "err01" });
          }
        } catch (error) {
          const message =
            error.response?.data?.message || error.response.statusText;
          toast.error(message, { toastId: "err01" });
        }
      },
    });
  return (
    <div className="modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content shadow-3">
        <div className="modal-header">
          <div className="h3 text-start">Create Patients</div>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          {/* Text */}
          <form onSubmit={handleSubmit}>
            <div className="mb-5">
              <label className="form-label" htmlFor="dateOfVisit">
                Date
              </label>
              <input
                type="date"
                id="dateOfVisit"
                name="registrationDate"
                value={values.registrationDate}
                onChange={handleChange}
                className={`form-control ${
                  touched?.registrationDate && Boolean(errors?.registrationDate)
                    ? "border-danger"
                    : ""
                }`}
              />
              <InputErrorMessage
                error={touched?.registrationDate && errors?.registrationDate}
                marginBottom={-15}
              />
            </div>
            <div className="mb-3 d-flex justify-content-between">
              <div>
                <div className="col-auto">
                  <label className="form-label" htmlFor="name">
                    Honorific
                  </label>
                  <select
                    type="string"
                    name="salutation"
                    value={values.salutation}
                    onChange={handleChange}
                    className={`form-select form-control-solid ${
                      touched?.salutation && Boolean(errors?.salutation)
                        ? "border-danger"
                        : ""
                    }`}
                  >
                    <option value="">...</option>
                    <option value="Dr.">Dr.</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Ms.">Ms.</option>
                  </select>
                  <InputErrorMessage
                    width = {`min-content`}
                    error={touched?.salutation && errors?.salutation}
                    marginBottom={-15}
                  />
                </div>
              </div>
              <div className="col-sm-5">
                <label className="form-label" htmlFor="fname">
                  First Name
                </label>
                <input
                  type="text"
                  id="fname"
                  name="firstName"
                  placeholder="Enter First Name"
                  value={values.firstName}
                  onChange={handleChange}
                  className={`form-control ${
                    touched?.firstName && Boolean(errors?.firstName)
                      ? "border-danger"
                      : ""
                  }`}
                />
                <InputErrorMessage
                  error={touched?.firstName && errors?.firstName}
                  marginBottom={-15}
                />
              </div>
              <div className="col-sm-5">
                <label className="form-label" htmlFor="lname">
                  Last Name
                </label>
                <input
                  type="text"
                  id="lname"
                  placeholder="Enter Last Name"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  className={`form-control ${
                    touched?.lastName && Boolean(errors?.lastName)
                      ? "border-danger"
                      : ""
                  }`}
                />
                <InputErrorMessage
                  error={touched?.lastName && errors?.lastName}
                  marginBottom={-15}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 col-sm-6">
                <label className="form-label" htmlFor="DOB">
                  Date of Birth
                </label>
                <input
                  type="date"
                  id="DOB"
                  name="dob"
                  value={values.dob}
                  onChange={handleChange}
                  className={`form-control ${
                    touched?.dob && Boolean(errors?.dob) ? "border-danger" : ""
                  }`}
                />
                <InputErrorMessage
                  error={touched?.dob && errors?.dob}
                  marginBottom={-15}
                />
              </div>
              <div className="col-12 col-sm-6">
                <label className="form-label" htmlFor="gender">
                  Gender
                </label>
                <select
                  name="gender"
                  value={values.gender}
                  onChange={handleChange}
                  className={`form-select ${
                    touched?.gender && Boolean(errors?.gender)
                      ? "border-danger"
                      : ""
                  }`}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
                <InputErrorMessage
                  error={touched?.gender && errors?.gender}
                  marginBottom={-15}
                  />
                </div>
              </div>
            <div className="mb-4">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <label className="form-label" htmlFor="mobileNumber">
                    {" "}
                    Phone No.
                  </label>
                  <input
                    type="text"
                    id="Email/mobileNumber"
                    name="contactNo"
                    value={values.contactNo}
                    onChange={handleChange}
                    className={`form-control ${
                      touched?.contactNo && Boolean(errors?.contactNo)
                        ? "border-danger"
                        : ""
                    }`}
                  />
                  <InputErrorMessage
                    error={touched?.contactNo && errors?.contactNo}
                    marginBottom={-15}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    className={`form-control ${
                      touched?.email && Boolean(errors?.email)
                        ? "border-danger"
                        : ""
                    }`}
                  />
                  <InputErrorMessage
                    error={touched?.email && errors?.email}
                    marginBottom={-15}
                  />
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label className="form-label" htmlFor="fullAddress">
                Full Address
              </label>
              <div className="row">
                <div className="col-12 col-sm-12 mb-2">
                  <input
                    type="text"
                    id="fullAddress"
                    placeholder="Address"
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    className={`form-control ${
                      touched?.address && Boolean(errors?.address)
                        ? "border-danger"
                        : ""
                    }`}
                  />
                  <InputErrorMessage
                    error={touched?.address && errors?.address}
                    marginBottom={-10}
                  />
                </div>
                <div className="col-6 col-sm-6 mb-2">
                  <input
                    type="text"
                    id="fullAddress"
                    placeholder="Zip Code"
                    name="zipcode"
                    value={values.zipcode}
                    onChange={handleChange}
                    className={`form-control ${
                      touched?.zipcode && Boolean(errors?.zipcode)
                        ? "border-danger"
                        : ""
                    }`}
                  />
                  <InputErrorMessage
                    error={touched?.zipcode && errors?.zipcode}
                    marginBottom={-15}
                  />
                </div>
                <div className="col-6 col-sm-6 mb-2">
                  <input
                    type="text"
                    id="fullAddress"
                    placeholder="City"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    className={`form-control ${
                      touched?.city && Boolean(errors?.city)
                        ? "border-danger"
                        : ""
                    }`}
                  />
                  <InputErrorMessage
                    error={touched?.city && errors?.city}
                    marginBottom={-15}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <button onClick={() => resetForm()}>
                <a className="btn btn-secondary w-sm-40">Clear</a>
              </button>
              <button type="submit">
                <a className="btn btn-primary w-sm-40">
                  <i className="bi bi-save"></i> Save
                </a>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PatientForm;
